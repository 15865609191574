import { Box } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

function Price({ label, value }) {
  return (
    <>
    {console.log(value)}
    {
      Boolean(value) && !isNaN(value) && 
      <Box>
      <div>{label}</div>
      <Box fontWeight="bold" fontSize={20} mt={0.75}>
        {value}
      </Box>
    </Box>
    }
    </>
  );
}

Price.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default Price;
