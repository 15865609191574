import { toggle_drawer } from "state/actions";
import { active_project, filter_rerender } from "state/store.global";
import { useSet, useGet, getFocus, setFocus } from "state/jotai";
import { _project } from "state/store.projects";
import { Icon } from "assets/Icons_";
import { Controls } from "./Filter.Controls";
import { Button } from "components/Button/Button";
import { Head, Bottom, Scroll, Count } from "./Filter.style";
import { useReset } from "./Filter.Reset";
import { PoweredBy } from "components/PoweredBy/PoweredBy";
import { motion } from "framer-motion";
import { Box } from "@mui/material";

export const Filter = () => {
  const err = { err_message: "look in Filter.jsx" };
  const { pin, pid } = useGet(active_project);
  const rerender = useGet(filter_rerender);
  const active = getFocus(_project, "activefilters")?.[pin]?.[pid] ?? err;
  const options = getFocus(_project, "filteroptions")?.[pin]?.[pid] ?? err;
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? err;
  const count = getFocus(_project, "units")?.[pin]?.[pid]?.Units?.length ?? "";
  const total =
    getFocus(_project, "unitsraw")?.[pin]?.[pid]?.Units?.length ?? "";
  const setFilters = setFocus(_project, "activefilters");
  const toggle = useSet(toggle_drawer);
  const reset = useReset({ global: false });

  const click = () => toggle("filter");
  const icon = { icon: "close6", color: clr?.icons, size: 18 };

  const a = {
    animate: { opacity: 1, transition: { duration: 0.5 } },
    initial: { opacity: 0 },
  };

  if (pid && pin >= 0)
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Head as={motion.div} {...a} onClick={click}>
            <Icon {...icon} />
          </Head>
          <Scroll as={motion.div} {...a}>
            <div style={{ height: 70 }} />
            <Controls
              {...{ active, options, setFilters, key: pid + rerender }}
            />
            {/* <div style={{height:70}}/> */}
            <Bottom bgColor={clr.primary}>
              <Button mode={0} click={reset} fontSize="1rem">
                {
                  buttonsLabel?.filter(
                    (btn) => btn?.UILabelProperty === "Nulstil"
                  )[0]?.Label
                }
              </Button>
              <Button mode={1} click={click} fontSize="1rem">
                {buttonsLabel
                  ?.filter((btn) => btn?.UILabelProperty === "Vis boliger")[0]
                  ?.Label?.split(" ")[0] +
                  " " +
                  count +
                  " " +
                  buttonsLabel
                    ?.filter((btn) => btn?.UILabelProperty === "Vis boliger")[0]
                    ?.Label?.split(" ")[1]}
              </Button>
            </Bottom>
            {/* <div style={{height:50}}/> */}
            {/* <Count> */}
            {/* { count && `${count} / ${total}` } */}
            {/* </Count> */}
            {/* <div style={{height:60}}/> */}
          </Scroll>
          {/* <PoweredBy/> */}
        </Box>
        <PoweredBy clr={clr.sub_text_color} />
      </Box>
    );
};
