import styled from "styled-components";
import { KIOSK } from "constants";

export const PoweredBy = ({ mode, clr = "#000" }) => {
  const href = "https://estatetool.dk";
  const style = { color: clr, fontWeight: 600 };
  const link = KIOSK ? (
    "estatetool"
  ) : (
    <a href={href} target="_blank" style={style}>
      estatetool
    </a>
  );

  return (
    <Powered clr={clr} mode={mode?.toString()}>
      Powered by {link} | &#169; {new Date().getFullYear()}
    </Powered>
  );
};

const Powered = styled.div`
  position: ${({ mode }) => (mode == "true" ? "unset" : "initial")};
  bottom: 10px;
  width: 100%;
  color: ${({ clr }) => clr};
  text-align: center;
  padding: 20px 0;
  white-space: nowrap;
  font-size: 0.75rem;
`;
