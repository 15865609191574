import styled from 'styled-components/macro'
import { active_project, static_assets } from 'state/store.global'
import { useAtomValue, useGet } from 'state/jotai'

export const Logo = ({ link = null, device , style }) => {
  const assets = useAtomValue(static_assets)
  const obj = assets[`logo_${device}`]

  // const { pin, pid }  = useGet(active_project);
  // const menus = getFocus(_project, "menuItems")?.[pin]?.[pid] ?? [];

  return (
    <>
      <A href={link} style={{style}}>
        <Wrap device={device} >
          <Img src={obj.src} alt='' device={device} />
        </Wrap>
      </A>
    </>
  )
}

export const Img = styled.img`
  /* border:1px solid black; */
  display:block;
  width:100%;
  /* width:unset; */
  /* height:unset; */
`

export const Wrap = styled.div`
  /* border:5px solid transparent; */
  display: flex;
  padding-left: 4px;
  padding-right: 4px;
  justify-content:center;
  /* border: 1px solid black; */
  height: 100%;
  width: 100%;
`
export const A = styled.a`
  /* border: 1px solid pink; */
  /* display: inline-block; */
  width:100%;
  height:100%;
  /* display: flex; */
  /* justify-content:center; */
  /* height: 100%; */
  /* width: 150px; */
`