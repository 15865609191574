import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  active_project,
  currentActiveProject,
  current_language,
  isMulti_project_active,
} from "state/store.global";
import { _bootstrap } from "state/store.bootstrap";
import { _project } from "state/store.projects";
import { getFocus, useAtom, useSet } from "state/jotai";
import { useNavigate } from "react-router-dom";
import { dropdownOptions } from "components/Dropdown2/Dropdown2.Data";

export const useSetActiveProject = () => {
  const [res, setRes] = useState(false);
  const multiActive = useAtom(isMulti_project_active)[0];
  const setMultiActive = useAtom(isMulti_project_active)[1];

  const type = getFocus(_bootstrap, "type");
  const location = useLocation().pathname;
  const names = getFocus(_bootstrap, "projectnames");
  const languages = getFocus(_bootstrap, "languages");
  const ids = getFocus(_bootstrap, "projectids");

  const [currentLanguage, setCurrentLanguage] = useAtom(current_language);
  const activeProject = useAtom(currentActiveProject)[0];
  const setActiveProject = useAtom(currentActiveProject)[1];
  const setActiveID = useSet(active_project);
  const navigateTo = useNavigate();

  useEffect(() => {
    if (location) {
      const loc = location.split("/");
      const language =
        languages.filter(l => l === loc[1])[0] ?? currentLanguage;
      const currentActiveProjectURL = location.split("/")[3];
      const currentProject = ["", "Isometry"].includes(activeProject)
        ? currentActiveProjectURL
        : activeProject;
    
      const findActiveProject = names[language]?.filter(
        lan => lan.Presentation === currentProject
      );
      let currentActiveProject = findActiveProject?.length
        ? findActiveProject[0]
        : names[language]?.[0];
        
        if (currentActiveProject?.PIN > 3) {
         return navigateTo(`/${language}${names[language]?.[0].path}`);
        }
        
   
        setActiveProject(currentProject)
  
      currentActiveProject =
        currentActiveProject?.PIN > 3
          ? names[language]?.[0]
          : currentActiveProject;

      const pin = currentActiveProject?.PIN;
      const pid = currentActiveProject?.PID;
      if (language) setCurrentLanguage(language);
      if (pin > 0) setMultiActive(true);
      else setMultiActive(false);
      if (!multiActive) setActiveID({ pid, pin });
      setRes(true);
    }
  }, [location, currentLanguage, multiActive]);

  return res;
};
